/* eslint-disable import/no-unresolved */
// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// components
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import DatePicker from 'commons/components/DatePicker';
import { truthty, falsy } from 'utils/functions';
import { defaultProcesses } from 'utils/defaultProcesses';


const ProcessForm = (props) => {
  const {
    toggleForm,
    isOpen,
    edit,
    controls,
    changeControls,
    invalidControls,
    processTypes,
    workShiftTypes,
    processCategories,
    submit,
    receiptLots,
  } = props;
  return (
    <Dialog fullWidth onClose={toggleForm} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle>{`${edit ? 'Editar' : 'Crear'} proceso`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <DatePicker
              inputId="date"
              label="Fecha"
              type="datetime-local"
              fullWidth
              value={controls.date}
              onChange={changeControls}
              inputProps={{ 'data-module': 'closeModal' }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {/* <FormControl fullWidth>
              <InputLabel id="process-type-label">Tipo de proceso</InputLabel>
              <Select
                labelId="process-type-label"
                fullWidth
                value={controls.processTypeId}
                onChange={changeControls}
                error={invalidControls.processTypeId}
              >
                <MenuItem id="processTypeId" value="">Ninguno</MenuItem>
                {Object.values(processTypes)
                  .filter((pt) => !defaultProcesses.includes(pt.name))
                  .map((processType) => (
                    <MenuItem
                      key={`process-type-form-option-${processType.id}`}
                      id="processTypeId"
                      value={processType.id}
                    >
                      {processType.name}
                    </MenuItem>
                  ))}
              </Select>
              {truthty(invalidControls.processTypeId) && <FormHelperText error>Debe ingresar un tipo de proceso</FormHelperText>}
            </FormControl> */}
            <Autocomplete
              value={
                controls.processTypeId &&
                Object.values(processTypes).find(
                  (processType) => processType.id === controls.processTypeId,
                )
              }
              id="autocomplete-processType-processes"
              options={Object.values(processTypes)
                .filter((processType) => !defaultProcesses.includes(processType.name))
                .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))}
              getOptionLabel={(processType) => processType && processType.name}
              renderInput={(params) => (
                <TextField
                  style={{ margin: 0 }}
                  {...params}
                  label="Tipo de proceso"
                  margin="normal"
                />
              )}
              onChange={(event, newValue) => {
                const e = {
                  autocomplete: true,
                  input: 'processTypeId',
                  value: newValue ? newValue.id : '',
                };
                changeControls(e);
              }}
            />
            {truthty(invalidControls.processTypeId) && (
              <FormHelperText error>Debe ingresar un tipo de proceso</FormHelperText>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="process-categories-label">Categoría de proceso</InputLabel>
              <Select
                labelId="process-categories-label"
                fullWidth
                value={controls.processCategoryId}
                onChange={changeControls}
                error={invalidControls.processCategoryId}>
                <MenuItem id="processCategoryId" value="">
                  Ninguno
                </MenuItem>
                {Object.values(processCategories).map((processCategory) => (
                  <MenuItem
                    key={`process-category-form-option-${processCategory.id}`}
                    id="processCategoryId"
                    value={processCategory.id}>
                    {processCategory.name}
                  </MenuItem>
                ))}
              </Select>
              {truthty(invalidControls.processCategoryId) && (
                <FormHelperText error>Debe ingresar una categoría de proceso</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="work-shift-type-label">Tipo de turno</InputLabel>
              <Select
                labelId="work-shift-type-label"
                fullWidth
                value={controls.workShiftTypeId}
                onChange={changeControls}
                error={invalidControls.workShiftTypeId}>
                <MenuItem id="workShiftTypeId" value="">
                  Ninguno
                </MenuItem>
                {Object.values(workShiftTypes).map((workShiftType) => (
                  <MenuItem
                    key={`work-shift-type-form-option-${workShiftType.id}`}
                    id="workShiftTypeId"
                    value={workShiftType.id}>
                    {workShiftType.name}
                  </MenuItem>
                ))}
              </Select>
              {truthty(invalidControls.workShiftTypeId) && (
                <FormHelperText error>Debe ingresar un tipo de turno</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              value={controls.admitedLotNumber && Object.values(receiptLots).find((rp) => rp.number === controls.admitedLotNumber)}
              disabled={edit || falsy(controls.processTypeId) || processTypes[controls.processTypeId].allowMultipleLots}
              id="autocomplete-admitedLotNumber-processes"
              options={Object.values(receiptLots).sort((a, b) => a.number - b.number)}
              getOptionLabel={(receiptLot) => receiptLot && receiptLot.number.toString() }
              renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Lote admitido" margin="normal" />}
              onChange={(event, newValue) => {
                const e = {
                  autocomplete: true,
                  input: 'admitedLotNumber',
                  value: newValue ? newValue.number : '',
                  module: 'formControls',
                };
                changeControls(e);
              }}
            />
            {falsy(processTypes[controls.processTypeId]?.allowMultipleLots) && truthty(invalidControls.admitedLotNumber) && <FormHelperText error>Debe ingresar un número de lote</FormHelperText>}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleForm} color="primary" variant="outlined">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={submit} autoFocus buttonId="create-edit-process">
          {edit ? 'Editar' : 'Agregar'}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  );
};

export default ProcessForm;
